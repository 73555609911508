@import "antd/dist/reset.css";
@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

main {
    background-size: 100% 30% !important;
    background-repeat: no-repeat
}

/* hide scrollbars */
.site-layout::-webkit-scrollbar,
.form-container::-webkit-scrollbar,
.pos-products::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none;
}
/* hide scrollbars */

svg { vertical-align: baseline; }

/* buttons */
.btn-primary {
    @apply !bg-primary text-body5 font-mulishMedium text-neutralWhite !border-transparent
}

.btn-secondary {
    @apply !bg-secondary text-body5 font-mulishMedium text-neutralWhite !border-transparent
}

.btn-default {
    @apply !bg-none text-body5 font-mulishMedium text-neutralBlack !border-neutralBlack-75
}

.btn-primary:hover, .btn-secondary:hover {
    opacity: 80%;
    @apply !border-transparent !text-neutralWhite 
}

.btn-default:hover {
    opacity: 80%;
    @apply !text-neutralBlack 
}

.ant-btn-primary.btn-default.transparent-hover {
    @apply hover:!bg-transparent
}

.ant-btn-default:disabled,
.ant-btn-primary:disabled,
.ant-btn-seconday:disabled {
    opacity: 50%;
    @apply text-body4 font-mulishMedium text-neutralWhite
}

.ant-btn {
    box-shadow: unset !important
}
/* buttons */

/* loaders */
.rs-loader-backdrop {
    z-index: 1;
    opacity: 40%;
}

.rs-loader-spin {
    z-index: 1;
}

.ant-spin {
    @apply !block
}

.ant-spin-dot-item {
    @apply !bg-secondary
}
/* loaders */

/* navbar */
.ant-menu {
    @apply !bg-neutralBlack !border-y-0
}

.ant-menu-item {
    @apply font-mulishMedium text-body3 
}

.ant-menu-item .ant-menu-title-content, .ant-menu-submenu .ant-menu-title-content, 
.ant-menu-submenu .ant-menu-submenu-arrow {
    @apply text-neutralWhite
}

.ant-menu-submenu-title {
    color: #FAFAFA !important
}

.ant-menu-sub .ant-menu-item-selected {
    background-color: #8C9297 !important;
}

.ant-menu-item-selected::after, .ant-menu-item:hover::after, .ant-menu-submenu-selected::after {
    @apply !border-y-neutralWhite
}

.header-account {
    filter: brightness(5);
}
/* navbar */

/* dropdown */
.ant-dropdown {
    max-height: 15rem;
    overflow-y: scroll;
}

.ant-dropdown-menu-item {
    width: 13rem !important;
}

.ant-dropdown-menu-title-content {
    @apply font-mulishMedium text-body4 text-neutralBlack
}

.ant-dropdown-menu-item-selected .ant-dropdown-menu-title-content {
    @apply text-neutralWhite
}

.ant-dropdown-menu-item-selected {
    @apply !bg-secondary
}

.ant-dropdown-menu {
    @apply !border !border-neutralBlack-25 !bg-neutralWhite-50
}
/* dropdown */

/* modals */
.ant-modal-confirm-body {
    display: unset !important;
}

.ant-modal-confirm-content, .ant-modal-confirm-btns {
    @apply !text-center
}

/* .ant-modal-content {
    left: 40%
} */

.form-modal .anticon-exclamation-circle, .form-modal .anticon-info-circle, 
.anticon-exclamation-circle,
.anticon-info-circle {
    @apply !hidden
}

.form-modal .ant-modal-content {
    /* min-width: 42em !important; */
    /* left: -20% !important; */
    @apply !pb-0
}

.form-modal .ant-modal-confirm-content {
    @apply !text-left
}
/* modals */


/* tables */
.ant-table-container .ant-table-header th, .ant-table-container .ant-table-header th::before {
    @apply text-body4 !text-neutralBlack font-mulishBold !text-center border-0 !bg-transparent
}

.ant-table-container .ant-table-row {
    @apply text-body5 !text-neutralBlack-75 font-mulishMedium !text-center border-0
}

.ant-pagination, .ant-select-selection-item, .ant-select-item-option-content {
    @apply text-body6 !text-neutralBlack-75 font-mulishMedium
}

.ant-select-selection-item {
    @apply !text-body7
}

.ant-pagination-item-active {
    @apply !border-neutralWhite-25 !bg-primary
}

.ant-pagination-item-active a {
    @apply !text-neutralWhite-75
}

.ant-tooltip-inner {
    @apply text-body6 !text-neutralWhite font-mulishMedium
}
/* tables */

/* form */
.form-container {
    /* max-height: 45rem;
    padding-right: 1rem !important;
    overflow-y: scroll; */
}

.app-form label {
    /* white-space: break-spaces; */
    @apply !text-body6 !text-neutralBlack font-mulishMedium
}

.pos-form {
    @apply !border-none
}

.pos-form .ant-card-body,
.product-card .ant-card-body {
    padding: unset !important;
}

.pos-form .ant-card-body {
    @apply !h-full
}

.pos-form .orders-section {
    max-height: 30rem !important;
    @apply p-4 overflow-y-scroll
}
/* form */

/* input */
.ant-input, .ant-input-number-input, .ant-select-selection-item, .ant-picker-input input {
    @apply !text-body5 !text-neutralBlack font-mulishMedium
}

.payment .ant-input,
.pos-payment .ant-input {
    @apply !text-body3
}

.ant-form-item-label {
    padding: unset !important;
}

.ant-select-selection-placeholder {
    @apply !text-body6 font-mulishMedium
}

.ant-input-disabled.ant-input-compact-first-item {
    height: 2rem;
}

.ant-select-item-option-disabled {
    @apply !bg-neutralBlack-25
}

.ant-form-item-explain-error {
    @apply !text-body6 !text-secondary font-mulishMedium float-left
}

.ant-input-prefix {
    margin-inline-end: 10px !important;
}
/* input */

/* tags */
.tag-white {
    color: #000000;
    background: #e8e8e8;
    border-color: #a0a39d;
}

.tag-gold {
    color: #d48806;
    background: #faf7ed;
    border-color: #ffe58f;
}

.tag-blue {
    color: #0958d9;
    background: #e6f4ff;
    border-color: #91caff;
}

.tag-red {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
}
/* tags */

.stream-iframe {
    background-image: url("../icons/spinner.gif");
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 576px) {
    .ant-picker-panels {
      flex-direction: column !important;
      align-content: center !important;
    }
}