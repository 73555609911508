@font-face {
    font-family: "MulishBlack";
    src: local("MulishBlack"),
     url("../fonts/Mulish-Black.ttf") format("truetype");
}

@font-face {
    font-family: "MulishBold";
    src: local("MulishBold"),
     url("../fonts/Mulish-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "MulishMedium";
    src: local("MulishMedium"),
     url("../fonts/Mulish-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "MulishLight";
    src: local("MulishLight"),
     url("../fonts/Mulish-Light.ttf") format("truetype");
}

@font-face {
    font-family: "MulishItalic";
    src: local("MulishItalic"),
     url("../fonts/Mulish-Italic.ttf") format("truetype");
}